import React, { Suspense, useEffect, useRef } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import routers from "./router";
import { getTokenToLocalStorage } from "./utils/token";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoadingDom from "./components/UI/LoadingDom";
import { getUserPermissions } from "./components/business/RolePermission";
import { Permissions } from "./components/business/RolePermission/permissions";
import { Message } from "@arco-design/web-react";
import { coming_soon_icon } from "./assets/user-center";

function App() {
  const element = useRoutes(routers);
  const location = useLocation();
  const routerPermissions = [
    {
      path: "/",
      exempt: true,
    },
    {
      title: "登录",
      path: "/login",
      exempt: true,
    },

    {
      title: "工作台",
      path: "/work-bench",
      exempt: true,
    },
    {
      title: "创作空间",
      path: "/creative-space",
      permissions: Permissions.CreativeSpace,
    },
    {
      subTitle: "爆款脚本",
      path: "/creative-script",
      permissions: Permissions.HotScript,
    },
    {
      subTitle: "创建脚本",
      path: "/creation-script",
      exempt: true,
    },
    {
      subTitle: "AI主题创作",
      path: "/ai-creation",
      permissions: Permissions.AIThemeCreation,
    },
    {
      subTitle: "AI视频解析",
      path: "/analysis-video",
      permissions: Permissions.AIVideoAnalysis,
    },
    {
      subTitle: "智能混剪",
      path: "/video-clips",
      permissions: Permissions.IntelligentEditing,
    },
    {
      subTitle: "一键成片",
      path: "/accomplish",
      nothingness: true,
    },
    {
      subTitle: "图文海报",
      path: "/graphicediting",
      nothingness: true,
    },
    {
      subTitle: "团队概览",
      path: "/team-overview",
      permissions: Permissions.TeamManagement,
    },
    {
      subTitle: "团队成员",
      path: "/team-anagement",
      permissions: Permissions.TeamManagement,
    },

    {
      title: "矩阵营销",
      path: `https://zhaixingfabu.wl1288.com/#/login?token=${getTokenToLocalStorage()}`,
      permissions: Permissions.MatrixMarketing,
      // nothingness: true,
    },
    {
      title: "任务中心",
      path: "/task-center",
      permissions: Permissions.TaskCenter,
    },
    {
      title: "个人中心",
      path: "/user",
      permissions: Permissions.UserCenter,
    },
    {
      path: "/ai-creation",
      exempt: true,
    },
    {
      path: "/creative-space/recycling-station",
      exempt: true,
    },
    {
      path: "/aithemecreationtable",
      exempt: true,
    },
    {
      path: "/aithemescriptcreate",
      exempt: true,
    },
    {
      path: "/creation-script/:id",
      exempt: true,
    },
    {
      path: "/team-anagement",
      exempt: true,
    },
    {
      path: "/team-overview",
      exempt: true,
    },
    {
      path: "/analysis-video",
      exempt: true,
    },
    {
      path: "/analysis-video/:id",
      exempt: true,
    },
    {
      path: "/mix-detail/:id/:script_id",
      exempt: true,
    },
    {
      path: "/mixcutDetail/:id",
      exempt: true,
    },
    {
      path: "/video-clip-result/:projectId/:count",
      exempt: true,
    },
    {
      path: "/invite",
      exempt: true,
    },
    {
      path: "/video-editing",
      exempt: true,
    },
  ];
  const navigate = useNavigate();
  const isRouter = () => {
    return routerPermissions.find((v) => {
      const regex = new RegExp(`^${v.path.replace(/:\w+/g, "\\w+")}$`, "i");
      return regex.test(location.pathname);
    });
  };

  useEffect(() => {
    const next = location.pathname + location.search;
    const queryParams = new URLSearchParams(location.search);
    const isNext = queryParams.get("next");

    if (!getTokenToLocalStorage()) {
      if (!isRouter() && isNext) {
        navigate(`login?next=${next}`);
        return;
      }
    }
  }, [navigate]);
  return (
    <HelmetProvider>
      <div>
        <Helmet></Helmet>
        <React.Fragment>
          <Suspense
            fallback={
              <LoadingDom isLoading>
                <div style={{ width: "100vw", height: "100vh" }}></div>
              </LoadingDom>
            }>
            {element}
          </Suspense>
        </React.Fragment>
      </div>
    </HelmetProvider>
  );
}

export default App;
